import { Helmet } from "react-helmet"

const NotFound = ()=>{
    return(
        
<>
<div>
  <Helmet>
    <title>404 Not Found</title>
  </Helmet>
  <div className="Poppins grid text-center place-items-center">
<div className='sm:text-8xl md:text-9xl mt-[6rem] font-bold'>
  404
  <div className='sm:text-3xl md:text-5xl'>
Page Not Found
  </div>
</div>
<div className='sm:text-md md:text-xl mt-5 md:max-w-[40rem] px-6'>
The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the
</div>
<div>
  <a href='/'><button  className='btn btn-accent btn-lg mt-12'>GO BACK</button></a>
</div>
  </div>
</div>
</>
    )
}
export default NotFound