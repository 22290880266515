import { useEffect, useState } from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Darkfoot from './parts/foobar';
import {Helmet} from "react-helmet";

const Re = ({match})=>{

  return (
                        <>
                                    <style>
        {
          ` body{
                background-color: #171718;
                color: white;
        
            }`
        }
      </style>
    <Helmet>
<title>Skyex|About us</title>
      </Helmet>
      <div className="">
              <NavBar />
              <div className='grid place-items-center'>
                <div className="text-center text-4xl">
                Return Policy
                </div>
                <article className='sm:w-[86vw] mt-4 text-md Poppins'>
<p>
Any claims for misprinted/damaged/defective items must be submitted within 30 days after the product has been received. For packages lost in transit, all claims must be submitted no later than 30 days after the estimated delivery date. Claims deemed an error on our part are covered at our expense.
</p>
<p>If you have any problems please contact us at supoort@skyex.me</p>
<h2>Wrong Address</h2>
<p>
If you provide an address that is considered insufficient by the courier, the shipment will be returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable).
</p>
<h2>Unclaimed</h2>
<p>
shipments that go unclaimed are returned to our facility and you will be liable for the cost of a reshipment to yourself (if and as applicable).
</p>
<h2>Returned by Customer</h2>
<p>
Customers residing in Brazil, we do not refund orders for buyer’s remorse. Returns for products, face masks, as well as size exchanges are to be offered at your expense and discretion. If you choose to accept returns or offer size exchanges to your end customers, you would need to place a new order at your expense for a face mask or a product in another size. Customers residing in Brazil and regretting a purchase must contact our Customer Service and express their will to return the item within 7 consecutive days after receiving it, providing a picture of the item. The withdrawal request will undergo an evaluation to verify whether the product was used or destroyed, even if partial. In these cases, a refund will not be possible.</p>
<h2>Notification for EU consumers</h2>
<p>
According to Article 16(c) and (e) of the Directive 2011/83/EU of the European Parliament and of the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for:

1. the supply of goods that are made to the consumer's specifications or are clearly personalized;
2. sealed goods which were unsealed after delivery and thus aren't suitable for return due to health protection or hygiene reasons,

therefore skyex reserves rights to refuse returns at its sole discretion.

This Policy shall be governed and interpreted in accordance with the English language, regardless of any translations made for any purpose whatsoever.</p>
                </article>
              </div>
          </div>
          <Darkfoot />
          </>
  )
}
export default Re