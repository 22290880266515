import { useEffect, useState } from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Darkfoot from './parts/foobar';
import {Helmet} from "react-helmet";

const Aboutus = ({match})=>{

  return (
                        <>
                                    <style>
        {
          ` body{
                background-color: #171718;
                color: white;
        
            }`
        }
      </style>
    <Helmet>
<title>Skyex|About us</title>
      </Helmet>
      <div className="">
              <NavBar />
              <div className='grid place-items-center'>
                <div className="text-center text-4xl">
About us
                </div>
                <article className='sm:w-[86vw] mt-4 text-md Poppins'>
                skyex is a new and innovative company that is shaking up the clothes industry. We believe that clothes should be more than just something you wear, they should be an extension of your personality. Our clothes are designed to do the talking for you, so you never have to worry about what to say or how to say it. Whether you're looking for the perfect outfit for a job interview or a first date, skyex has you covered. We believe that looking good should be easy and effortless, and with our clothes, it is. So ditch the wardrobe worries and start expressing yourself with skyex.

                </article>
              </div>
          </div>
          <Darkfoot />
          </>
  )
}
export default Aboutus