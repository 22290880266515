import { useEffect, useState } from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import { useNavigate,useParams } from 'react-router-dom'
import Darkfoot from './parts/foobar';
import ProgressiveImage from "react-progressive-image-loading";
const CatagoryView = ({match})=>{
  const navigate = useNavigate();
  const [state, setstate] = useState('loading')
  const [catagorys, setcatagorys] = useState([])
  const [products, setproducts] = useState([])
  const [limit, setlimit] = useState(10)
  const [collectionnumber, setcollectionnumber] = useState(0)
  const [activetab, setactivetab] = useState()
  const perm = useParams()
  const catname = perm.name
  useEffect(()=>{
    window.scrollBy(0,-339999);
  })
  useEffect(()=>{

axios.post(window.$api + 'getcatagorysshoptoo',{id:catname}).then((Response)=>{
  setcollectionnumber(1)
  if(Response.data.status){
    // console.log(Response.data.status)
  }else{
    if(Response.data){
    setcatagorys(Response.data)
    axios.post(window.$api + 'getproductsbycategory',{limit:limit,id:catname}).then((Response)=>{
      console.log(Response.data)
    
      setcollectionnumber(2)
      if(Response.data.status){
    
      }else{
        setproducts(Response.data)
        setcollectionnumber(2)
      }
    if(Response.data.length == 0){
      setcollectionnumber(2)
    }
    })
    }

  }
})
  },[limit])
  
  useEffect(()=>{
    if(collectionnumber == 2){
      setstate('project')
    }else{
      setstate('loading')
    }
  },[collectionnumber,products,catagorys])



  






if(state == 'project'){
  return (
    
<>
<style>
        {
          ` body{
                background-color: #171718;

  
            }`
        }
            </style>
<NavBar/>
<div className=' text-3xl font-bold bg-[#171718]	 å mt-5 max-w-7xl lg:max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"'>
{catagorys[0].name}
</div>
<div className='grid place-items-center'>

<div className="px-2 max-w-[100vw]">
<div className=" focus:outline-none mt-3 h-10 elative w-auto max-w-96 flex gap-6 scrollbar-hide snap-x  scroll-p-8 hover:scroll-p-0 will-change-scroll overflow-x-auto ">

</div>
</div>

</div>
<center>

          <>


  <div className="grid place-items-center -mt-5 grid-cols-2 px-3 gap-x-3 gap-y-6 sm:w-screen  md:max-w-[40rem]">

          {products.map((product) =>{
            const imgp = JSON.parse(product.media);
          
            if(imgp.length > 0){
              
            
            const img = imgp[0].image
            if(product.for == activetab){
              if(products.length == 0){
return(
  <>
  There's no products in this Store
  </>
)
              }else if(products.length == 1){
                return (
                  <>
     <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="">
        <img className="h-52 object-cover rounded-lg shadow-xl bg-white" src={img} />
        <span className="float-left w-[20%]  roboto">{product.name}</span>
        <br />
        <span className=" ">{product.price}$</span>
      </div>
        
                
                  </>
                )
              }else if(products.length > 1){
              return (
                <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className=" cursor-pointer max-w-[12rem]  bodrder">
<ProgressiveImage
    preview="/images/tiny-preview.png"
    src={img}
    render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
/>
<span className="float-left w-[50%] text-left mt-2">{product.name}</span>
<span className="float-right mt-2">{product.price}$</span>
</div>


      
              
                </>
              )}

            }
            }else if(imgp.length == 0){
              return (
                // 
                <>
   <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal scrollbar-hide snap-center shrink-0 first:pl-8 last:pr-8">
      <img className="shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcRiaxVlv_hi5y9807GnQ8XbtzHEuVv3CYjg&usqp=CAU'} />

    </div>
      
              
                </>
              )
            }
          })}

          </div>
          
        
          </>

</center>
<Darkfoot />
   

 </>
  )}else if(state == 'loading'){
    return(
      <>
      <NavBar />
      <div className="ml-[50%] mt-[30vh]">
        <div className="snippet" data-title=".dot-pulse">
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      </div>
</>
    )

  }
}
export default CatagoryView
