import { useEffect, useState } from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Darkfoot from './parts/foobar';
import {Helmet} from "react-helmet";
import ProgressiveImage from "react-progressive-image-loading";
const Shopw = ({match})=>{
  const navigate = useNavigate();
  const [state, setstate] = useState('loading')
  const [catagorys, setcatagorys] = useState([])
  const [products, setproducts] = useState([])
  const [limit, setlimit] = useState(10)
  const [collectionnumber, setcollectionnumber] = useState(0)
  const [activetab, setactivetab] = useState()
  const [searchin, setsearchin] = useState('')
  useEffect(()=>{
    window.scrollBy(0,-339999);
  })
  useEffect(()=>{

axios.post(window.$api + 'getcatagorysshop',{limit:limit}).then((Response)=>{
  setcollectionnumber(1)
  if(Response.data.status){
    // console.log(Response.data.status)
  }else{
    if(Response.data){
    setcatagorys(Response.data)
    setactivetab(Response.data[0].id)
    axios.post(window.$api + 'getproductsshopw',{limit:limit}).then((Response)=>{
      console.log(Response.data)
    
      setcollectionnumber(2)
      if(Response.data.status){
    
      }else{
        setproducts(Response.data)
        setcollectionnumber(2)
      }
    if(Response.data.length == 0){
      setcollectionnumber(2)
    }
    })
    }

  }
})
  },[limit])
  
  useEffect(()=>{
    if(collectionnumber == 2){
      setstate('project')
    }else{
      setstate('loading')
    }
  },[collectionnumber,products,catagorys])



  






if(state == 'project'){
  return (
    
<div className="bg-[#171718]">
  <Helmet>
  <title>Skyex</title>
  </Helmet>
<NavBar/>
<div className='md:flex sm:hidden mb-32 md:mt-32 px-20'>
<div className=''>
    {/* text */}
    {/* line */}
    <div className='flex'>
    <div className="w-[1rem]  h-[0.1rem] bg-white"></div>
    <div className='-mt-3 ml-4 text-[#656D72] text-base'>
    {/* MY NAME IS */}
    </div>
    </div>
    <div className='text-6xl leading-normal
 mt-8 sm:ml-2 md:ml-4'>
        LET YOUR<span className='text-[#7E74F1]'> CLOTHES</span><br />DO THE <span className='text-accent'>TALKING</span>
    </div>
    <div className='mt-5 sm:ml-2 md:ml-4 sm:px-2 md:px-0  md:max-w-[40vw] inter text-[#F1F1F1]'>
    <article>
    Have You ever been in a situation where you thought to yourself wtf is really going on but you couldn't say it well now your clothes would do it for you     </article>
    </div>
    
    <div className='mt-6 ml-4 max-w-[40vw] flex inter text-[#F1F1F1]'>
    <a><i class="fa-brands sm:text-3xl md:text-2xl mr-4 cursor-pointer fa-instagram"></i></a>
    <i class="fa-brands sm:text-3xl md:text-2xl mr-4 cursor-pointer fa-github"></i>
    <a href="mailto:support@skyex.me"><i class="fa-regular  sm:text-3xl md:text-2xl mr-4 cursor-pointer fa-envelope"></i></a>
    {/* <i class="fa-brands sm:text-3xl md:text-2xl mr-4 cursor-pointer fa-twitter"></i> */}
    {/* <i class="fa-brands sm:text-3xl md:text-2xl mr-4 cursor-pointer fa-linkedin-in"></i> */}
    </div>

   
</div>
<div className='md:ml-auto grid  place-items-center'>

    <div className='md:w-[25rem] sm:w-[64vw]  rounded-xl  -rotate-12 sm:mt-16 md:-mt-10 md:h-[26rem] sm:h-[19rem] bg-[#7E74F1]'>
    {/* <div className='md:w-[19rem] sm:w-[64vw] rounded-xl  rotate-12  md:h-[22rem] sm:h-[19rem] bg-[#eae9e9]'></div> */}
    <ProgressiveImage
    preview="/images/tiny-preview.png"
    src="/m.webp"
    render={(src, style) => <img className='mt-4 rounded-xl rotate-12 '  src={src} style={style} />}
/>
    </div>
</div>

</div>





{catagorys.map((iwgf,i)=>{
  return(
    <div className='sm:hidden md:grid place-items-center mt-6'>
    <div className='text-4xl mb-10'>
    {iwgf.name}
    </div>
    <center>
    
    <>
    <div className="relative mt-8 rounded-xl justify-center grid overflow-auto scrollbar-hide">  
    <div className="relative w-auto max-w-96 flex gap-6 scrollbar-hide snap-x  scroll-p-8 hover:scroll-p-0 will-change-scroll overflow-x-auto pb-14">
    
    {products.map((product) =>{
      const imgp = JSON.parse(product.media);
    
      if(imgp.length > 0){
        
      
      const img = imgp[0].image
      if(product.for === iwgf.id){
        if(products.length == 0){
    return(
    <>
    There's no products in this Store
    </>
    )
        }else if(products.length == 1){
          return (
            <>
    <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide justify-center snap-center shrink-0 first:pl-8 last:pr-8">
    <ProgressiveImage
        preview="/images/tiny-preview.png"
        src={img}
        render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
    />
    <span className="float-left m-2">{product.name}</span>
    <span className="float-right m-2">{product.price}$</span>
    </div>
    
          
            </>
          )
        }else if(products.length > 1){
        return (
          <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className=" cursor-pointer max-w-[12rem]  bodrder">
<ProgressiveImage
    preview="/images/tiny-preview.png"
    src={img}
    render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
/>
<span className="float-left w-[50%] text-left mt-2">{product.name}</span>
<span className="float-right mt-2">{product.price}$</span>
</div>


        
          </>
        )}
    
      }
      }else if(imgp.length == 0){
        if(product.for === activetab){
          if(products.length == 0){
    return(
    <>
    There's no products in this Store
    </>
    )
          }else if(products.length == 1){
            return (
              <>
    <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide snap-center shrink-0 first:pl-8 last:pr-8">
    <img className="shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcRiaxVlv_hi5y9807GnQ8XbtzHEuVv3CYjg&usqp=CAU'} />
    
    </div>
    
    
            
              </>
            )
          }else if(products.length > 1){
          return (
            <>
    <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide snap-center shrink-0 first:pl-8 last:pr-8">
    <img className="shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcRiaxVlv_hi5y9807GnQ8XbtzHEuVv3CYjg&usqp=CAU'} />
    
    </div>
    
    
          
            </>
          )}
    
        }
        return (
          // 
          <>
    
        
          </>
        )
      }
    })}
         <div onClick={()=>{navigate('/shop/category/' + activetab, { replace: false })}}  className="snap-normal cursor-pointer scrollbar-hide justify-center snap-center shrink-0 first:pl-8 last:pr-8">
    <div className="shrink-0 w-48 h-52 object-cover rounded-lg bg-[#00000080]">
    <div className="hero roboto pt-[4.8rem] font-bold text-white">
    <i class="fa-solid fa-eye text-4xl"></i>
      View All
    </div>
    </div>
    <span className="float-left m-2"></span>
    
    </div>
    </div>
    
    </div>
    
    </>
    
    </center>
    
    </div>
  )
})}

<div className='w-full mt-10 sm:grid md:hidden'>
  <div className='text-white text-5xl leading-relaxed
 Poppins w-full px-2 grid place-items-center text-center'>
   LET YOUR   <br /> <span className='text-accent'>CLOTHES</span>DO THE TALKING<br />  
  </div>
  <div className='px-6 mb-10'>
  <ProgressiveImage
    preview="/images/tiny-preview.png"
    src="/m.webp"
    render={(src, style) => <img className='mt-4 rounded-xl'  src={src} style={style} />}
/>

    
  </div>
  
  <center>

<>

{catagorys.map((iwgf,i)=>{
  return(
    <div className="relative mt-2 rounded-xl justify-center grid overflow-auto scrollbar-hide">  
<div className="Poppins text-3xl text-left ml-8 mb-5">
{iwgf.name}
</div>
<div className="relative w-auto max-w-96 flex gap-6 scrollbar-hide snap-x  scroll-p-8 hover:scroll-p-0 will-change-scroll overflow-x-auto pb-14">

{products.map((product) =>{
  const imgp = JSON.parse(product.media);

  if(imgp.length > 0){
    
  
  const img = imgp[0].image
  if(product.for === iwgf.id){
    if(products.length == 0){
return(
<>
There's no products in this Store
</>
)
    }else if(products.length == 1){
      return (
        <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide justify-center snap-center shrink-0 first:pl-8 last:pr-8">
<ProgressiveImage
    preview="/images/tiny-preview.png"
    src={img}
    render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
/>
<span className="float-left m-2">{product.name}</span>
<span className="float-right m-2">{product.price}$</span>
</div>

      
        </>
      )
    }else if(products.length > 1){
    return (
      <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal x cursor-pointer scrollbar-hide justify-center snap-center shrink-0 first:pl-8 last:pr-8">
<ProgressiveImage
    preview="/images/tiny-preview.png"
    src={img}
    render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
/>
<span className="float-left w-[50%] text-left mt-2">{product.name}</span>

<span className="float-right mt-2">{product.price}$</span>
</div>
{/* <div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className=" cursor-pointer max-w-[12rem]  bodrder">
<ProgressiveImage
    preview="/images/tiny-preview.png"
    src={img}
    render={(src, style) => <img className='shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white '  src={src} style={style} />}
/>
<span className="float-left w-[50%] text-left mt-2">{product.name}</span>
<span className="float-right mt-2">{product.price}$</span>
</div>
 */}
    
      </>
    )}

  }
  }else if(imgp.length == 0){
    if(product.for === activetab){
      if(products.length == 0){
return(
<>
There's no products in this Store
</>
)
      }else if(products.length == 1){
        return (
          <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide snap-center shrink-0 first:pl-8 last:pr-8">
<img className="shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcRiaxVlv_hi5y9807GnQ8XbtzHEuVv3CYjg&usqp=CAU'} />

</div>


        
          </>
        )
      }else if(products.length > 1){
      return (
        <>
<div onClick={()=>{navigate('/product/'+ product.id, { replace: false })}} key={product.id} className="snap-normal cursor-pointer scrollbar-hide snap-center shrink-0 first:pl-8 last:pr-8">
<img className="shrink-0 w-48 h-52 object-cover rounded-lg shadow-xl bg-white" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcRiaxVlv_hi5y9807GnQ8XbtzHEuVv3CYjg&usqp=CAU'} />

</div>


      
        </>
      )}

    }
    return (
      // 
      <>

    
      </>
    )
  }
})}
     <div onClick={()=>{navigate('/shop/category/' + activetab, { replace: false })}}  className="snap-normal cursor-pointer scrollbar-hide justify-center snap-center shrink-0 first:pl-8 last:pr-8">
<div className="shrink-0 w-48 h-52 object-cover rounded-lg bg-[#00000080]">
<div className="hero roboto pt-[4.8rem] font-bold text-white">
<i class="fa-solid fa-eye text-4xl"></i>
  View All
</div>
</div>
<span className="float-left m-2"></span>

</div>
</div>

</div>
  )
})}

</>

</center>
  <div className='grid w-[80vw]'>
  <div className="alert alert-info shadow-lg">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <span>Looking for staff <a href="https://skyex.me/jobs" className="underline">APPLY HERE</a>.</span>
  </div>
</div>
{/* <div className='w-full grid text-xl place-items-center Poppins px-6 text-white'>
<span className='text-accent text-center '>HAVE </span><br />You ever been in a situation where you thought to yourself wtf is fuck is really going on but you couldn't say it well now your shirt could.
</div> */}
<div>
  
</div>
  </div>
</div>


<Darkfoot />
   

 </div>
  )}else if(state == 'loading'){
    return(

      <div className="bg-[#171718]">
                    {/* <Helmet> */}
                    <Helmet>
     <title>Skyex</title>
      </Helmet>
            {/* <title>WTF IS GOING ON</title> */}
            <style>
            <style>
        {
          ` body{
                background-color: #171718;
                color: white;
                font-size: 2em
            }`
        }
      </style>
            </style>
            {/* </Helmet> */}
      <NavBar />
      <div className="ml-[50%] bg-[#171718 mt-[30vh]">
        <div className="snippet" data-title=".dot-pulse">
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
      </div>
</div>
    )

  }
}
export default Shopw